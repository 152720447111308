// Example starter JavaScript for disabling form submissions if there are invalid fields
(function() {
   'use strict';
   window.addEventListener(
      'load',
      function() {
         // Fetch all the forms we want to apply custom Bootstrap validation styles to
         var forms = document.getElementsByClassName('needs-validation');
         // Loop over them and prevent submission
         var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener(
               'submit',
               function(event) {
                  event.preventDefault();
                  event.stopPropagation();
                  form.classList.add('was-validated');
                  if (form.checkValidity()) {
                     submitFormAsync(form).then(function(data) {
                        //clearForm(form);
                        //alert(data);
                     });
                  }
               },
               false
            );
         });
      },
      false
   );

   function submitFormAsync(form) {
      if (!form) return;
      var formData = createFormData(form);

      return $.ajax({
         url: formData.submitURL,
         type: 'POST',
         success: function(data) {
            $(formData.submitSuccses).html(data);
            console.log('data', data);
         },
         data: formData
         //contentType: 'application/json',
         //data: JSON.stringify(formData)
      });

      // return $.post('src/php/pages/contact/content.php', formData, function(data) {
      //    $('#kontakt').html(data);
      //    // console.log('data',data);
      // });
   }

   function createFormData(form) {
      var formElements = form.elements;
      console.log('formEle', formElements);
      var formData = {};
      for (var i = 0; i <= formElements.length - 1; i++) {
         var elementId = formElements[i].id;
         var elementValue = formElements[i].value;
         var elementType = formElements[i].type;
         if (formData[elementId]) continue;
         if (elementType === 'checkbox') {
            var elementChecked = formElements[i].checked;
            var elementName = formElements[i].name;
            if (!formData[elementName] || !Array.isArray(formData[elementName]))
               formData[elementName] = [];
            if (elementChecked && !isAlreadyAddedInStorage(formData[elementName], elementId)) {
               formData[elementName].push(elementId);
            }
            continue;
         }
         formData[elementId] = elementValue;
         //formData.submitURL = 'src/php/pages/contact/content.php?tryb=1';
         //formData.submitURL = 'contact.php?tr=1';
         //formData.submitURL = 'src/php/pages/contact/contact-save.php';
      }
      console.log('formData', formData);
      return formData;
   }

   function clearForm(form) {
      var excludedInputIds = ['submitURL'];
      var excludedInputTypes = ['button'];
      var formElements = form.elements;

      for (var i = 0; i <= formElements.length - 1; i++) {
         var element = formElements[i];
         var elementId = formElements[i].id;
         var elementType = formElements[i].type;
         if (
            excludedInputIds.indexOf(elementId) > -1 ||
            excludedInputTypes.indexOf(elementType) > -1
         ) {
            continue;
         }
         if (elementType === 'checkbox') element.checked = false;
         else element.value = '';
      }
      form.classList.remove('was-validated');
   }

   function isAlreadyAddedInStorage(storage, item) {
      if (Array.isArray(storage)) {
         return storage.indexOf(item) > -1;
      }
      return false;
   }
})();

///////////////////////////////////////////////////////////////////////////////////////
