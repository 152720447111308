const $faturecard = $('.single-card-box');

function blokStartowy() {
   // Scrolled menu effect
   //console.log('start blok !!!');
   const $mainMenu = $('#main-navigation');
   const $backToTop = $('#back_to_top');
   const $footer = $('#footer');
   // const $heroSection = $('#hero');
   const $brandImg = $mainMenu.find('.navbar-brand img');
   const $pageMenu = $('.page-navigation');
   const $topMenu = $('#top-navigation');

   //const $itemLang = $('#item-lang');

   const makeMenuDefault = () => {
      $topMenu.removeClass('scrolled');

      $mainMenu.removeClass('navbar-light scrolled');
      $mainMenu.addClass('navbar-dark');

      $brandImg.attr('src', $brandImg.data('default-img'));
      $brandImg.removeClass('scrolled');

      //$backToTop.removeClass('scrolled');

      $pageMenu.addClass('color-navigation');
   };

   const makeMenuScrolled = () => {
      $topMenu.addClass('scrolled');

      $mainMenu.removeClass('navbar-dark');
      $mainMenu.removeClass('color-navigation');
      $mainMenu.addClass('navbar-light scrolled');

      $brandImg.addClass('scrolled');
      $brandImg.attr('src', $brandImg.data('scrolled-img'));

      //$backToTop.addClass('scrolled');
   };

   if ($(this).scrollTop() > 10) {
      makeMenuScrolled();
   }

   // On scroll
   $(window).scroll(function () {
      if ($(this).scrollTop() > 10) {
         makeMenuScrolled();
      } else {
         // When navbar is collapsed
         if (!$('.navbar-collapse').hasClass('show')) {
            makeMenuDefault();
         }
      }

      if ($(this).scrollTop() > 300) {
         $backToTop.addClass('scrolled');
      } else {
         $backToTop.removeClass('scrolled');
      }
   });

   // Collapse events
   const $navbarCollapse = $('#navbar-collapse');

   // Show the collapsed links
   $navbarCollapse.on('show.bs.collapse', function () {
      if (!$mainMenu.hasClass('scrolled')) {
         makeMenuDefault();
      }
   });

   // Hide the collapsed links
   $navbarCollapse.on('hide.bs.collapse', function () {
      if (!$mainMenu.hasClass('scrolled')) {
         makeMenuScrolled();
      }
   });

   // ScrollSpy and smooth scroll
   $mainMenu.find('.nav-link-mainMenu').click(function (e) {
      e.preventDefault();
      //$xid = $(this);
      //console.log('to menu 1', $xid);
      const scrollTo = $(this).attr('href');
      //console.log('href', $(this).attr('href'));
      $('#navbar-collapse').collapse('hide');
      $('html, body').animate(
         {
            scrollTop: $(scrollTo).offset().top - 90,
         },
         300
      );
   });

   $footer.find('.nav-link-mainMenu').click(function (e) {
      e.preventDefault();
      //$xid = $(this);
      //console.log('to menu 1', $xid);
      const scrollTo = $(this).attr('href');
      //console.log('href', $(this).attr('href'));
      $('#navbar-collapse').collapse('hide');
      $('html, body').animate(
         {
            scrollTop: $(scrollTo).offset().top - 90,
         },
         300
      );
   });

   $backToTop.on('click', function (e) {
      e.preventDefault();
      //$xid = $(this);
      //console.log('to menu', $xid);
      const scrollTo = $(this).attr('href');
      //console.log('href', $(this).attr('href'));
      $('#navbar-collapse').collapse('hide');
      $('html, body').animate(
         {
            scrollTop: $(scrollTo).offset().top - 90,
         },
         300
      );
   });

   // $heroSection.find('.hero-button').click(function (e) {
   //    e.preventDefault();

   //    const scrollTo = $(this).attr('href');
   //    //console.log('to guzik');
   //    // Collapse the menu if it's open
   //    $('#navbar-collapse').collapse('hide');

   //    $('html, body').animate(
   //       {
   //          scrollTop: $(scrollTo).offset().top - 120,
   //       },
   //       300
   //    );
   // });

   //////////////////////////// POLITYKI i COOKIE /////////////////////////////////////////

   $('div.desc').hide();
   $('div.nestedContent').hide();
   $('div.nestedContent1').hide();
   $('h3.title, div.desc li a').click(function () {
      $(this).next('div').toggle();
   });
   $('a.readMore').click(function () {
      $('div#showContent').show();
   });

   $('a.gegevens').click(function () {
      $('div#showContent1').show();
   });

   $('a.findMore').click(function () {
      $('.nestedContent1').toggle();
   });

   $('.js-read-more').on('click', function (e) {
      e.preventDefault();
      $(this).hide();
      $(this).next('.hidden-content').show();
   });

   $('.js-read-less').on('click', function (e) {
      e.preventDefault();

      $(this).parents('.hidden-content').hide();
      $(this).parents('.hidden-content').prev('.js-read-more').show();
   });

   /////////////////////////////////////////////////////////////////////
}

$(document).ready(function () {
   console.log('start !!! !!!');
   blokStartowy();
   $('.owl-carousel').owlCarousel({
      items: 3,
      loop: true,
      nav: true,
      dots: false,
      margin: 0,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      // lazyLoad: true,
      // stagePadding: 5,
      responsiveClass: true,
      responsive: {
         0: {
            items: 1,
            nav: false,
            dots: false,
         },
         485: {
            items: 2,
            nav: false,
            dots: false,
         },
         720: {
            items: 3,
            nav: false,
            dots: false,
         },

         960: {
            items: 3,
            nav: false,
            dots: false,
         },

         1200: {
            items: 4,
            nav: false,
            dots: false,
         },

         1800: {
            items: 5,
            nav: true,
            dots: false,
            loop: false,
         },
      },
   });

   $('.owl-carousel').on('mousewheel', '.owl-stage', function (e) {
      if (e.deltaY > 0) {
         $('.owl-carousel').trigger('next.owl');
      } else {
         $('.owl-carousel').trigger('prev.owl');
      }
      e.preventDefault();
   });
}); //////////////////////////////////////////////////////////////////////////////
